import React, { useEffect } from 'react';
import { GetServerSideProps, Redirect } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { moneyToDisplayPrice } from '@ariessolutionsio/react-ecomm-ui/dist/utils/maps';
import { useFormat } from 'helpers/hooks/useFormat';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { createClient, ResponseError, LocaleStorage, useDarkMode } from 'frontastic';
import { FrontasticRenderer } from 'frontastic/lib/renderer';
import { tastics } from 'frontastic/tastics';
import styles from './slug.module.css';
import { Log } from '../helpers/errorLogger';

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
};

export default function Slug({ data, locale }: SlugProps) {
  LocaleStorage.locale = locale;

  const { applyTheme } = useDarkMode();

  const { query, asPath } = useRouter();

  const isCategoryPage: boolean = query?.path?.includes('/category/');
  const isProductPage: boolean = query?.path?.includes('/p/');

  useEffect(() => {
    applyTheme(data?.pageFolder?.configuration?.theme);
  }, [data?.pageFolder?.configuration]);

  const { formatMessage } = useFormat({ name: 'common' });

  if (!data || typeof data === 'string') {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  if (!data!.ok && data!.message) {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data!.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  const site_url = process.env.NEXT_PUBLIC_SITE_URL || 'https://www.christiecookies.com';

  const constructCollectionSchema = (data) => {
    if (!data) return null;

    const category = data?.query?.category?.split('_')?.join(' ');

    const collectionSchema = {
      '@context': 'https://schema.org',
      '@type': 'CollectionPage',
      name: data?.metaTitle,
      url: `${site_url}${query.path}`,
      mainEntity: {
        '@type': 'ItemList',
        itemListElement: data?.items?.map((product, index) => {
          return {
            '@type': 'ListItem',
            position: index + 1,
            name: product?.name,
            url: `${site_url}${asPath}`,
            item: {
              '@type': 'Product',
              name: product?.name,
              url: `${site_url}${product?._url}`,
              image: product?.variants?.[0]?.images?.[0],
              category: category,
              description: product?.description,
              offers: {
                '@type': 'Offer',
                priceCurrency: product?.variants?.[0]?.price?.currencyCode,
                url: `${site_url}${product?._url}`,
                price: product?.variants?.[0]?.price
                  ? moneyToDisplayPrice(product?.variants?.[0]?.price).replace('$', '')
                  : null,
                availability: `http://schema.org/${product?.variants?.[0]?.isOnStock ? 'InStock' : 'OutOfStock'}`,
              },
              review: [[]],
              aggregateRating: [],
            },
          };
        }),
      },
    };

    return JSON.stringify(collectionSchema);
  };

  const constructProductSchema = (data) => {
    if (!data) return null;

    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    const formattedDate = `${date?.getFullYear()}-${date?.getMonth() + 1}-${date?.getDate()}`;
    const displayPrice = data?.variants?.[0]?.price ? moneyToDisplayPrice(data?.variants?.[0]?.price) : null;
    const productSchema = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: data?.name,
      image: data?.variants?.[0]?.images?.[0],
      description: data?.description,
      mpn: data?.variants?.[0]?.sku,
      brand: {
        '@type': 'Thing',
        name: 'Christie Cookie Co.', //data?.variants?.[0]?.attributes?.cookie_flavor?.label,
      },
      offers: {
        '@type': 'Offer',
        priceCurrency: data?.variants?.[0]?.price?.currencyCode,
        price: displayPrice.replace('$', ''),
        priceValidUntil: formattedDate,
        availability: `http://schema.org/${data?.variants?.[0]?.isOnStock ? 'InStock' : 'OutOfStock'}`,
        seller: {
          '@type': 'Organization',
          name: 'Christie Cookie Co.',
        },
      },
    };
    return JSON.stringify(productSchema);
  };

  const constructstructuredDataSchema = () => {
    const structuredData = {
      '@context': 'https://www.schema.org',
      '@type': 'Organization',
      '@id': site_url,
      name: 'The Christie Cookie Co.',
      brand: 'The Christie Cookie Co.',
      logo: 'https://res.cloudinary.com/dlwdq84ig/image/upload/v1679425727/bw7kkih9lahoxnitteza.png',
      description:
        "Christie Cookie Co. is America's favorite cookie! Our beautiful and customizable tins make the perfect gift.",
      url: site_url,
      sameAs: [
        'https://www.instagram.com/christiecookieco',
        'https://www.facebook.com/ChristieCookieCo/',
        'https://www.linkedin.com/company/christie-cookie-co/',
      ],
    };
    return JSON.stringify(structuredData);
  };

  const constructWebsiteDataSchema = () => {
    const websiteData = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: site_url,
      potentialAction: {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: `${site_url}/search/{search_term_string}`,
        },
        'query-input': 'required name=search_term_string',
      },
    };
    return JSON.stringify(websiteData);
  };

  let metaTitle = data?.pageFolder?.configuration?.seoTitle?.en_US;
  let metaDescription = data?.pageFolder?.configuration?.seoDescription?.en_US;

  if (isCategoryPage || isProductPage) {
    metaTitle = data?.data?.dataSources?.__master?.metaTitle || data?.data?.dataSources?.__master?.product?.metaTitle;
  }

  if (isCategoryPage || isProductPage) {
    metaDescription =
      data?.data?.dataSources?.__master?.metaDescription ||
      data?.data?.dataSources?.__master?.product?.metaDescription ||
      formatMessage({ id: 'meta.desc', defaultMessage: 'Find largest shopping collections here!' });
  }

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="title" content={metaTitle} />
        {site_url && <link rel="canonical" href={`${site_url}${query?.path}`} />}
        {query?.path?.includes('/p/') && (
          <>
            <meta property="og:type" content="product" />
            <meta property="og:title" content={data?.data?.dataSources?.__master?.product?.metaTitle} />
            <meta
              property="og:image"
              content={data?.data?.dataSources?.__master?.product?.variants?.[0]?.images?.[0]}
            />
            <meta property="og:description" content={data?.data?.dataSources?.__master?.product?.metaDescription} />
            {site_url && (
              <meta property="og:url" content={`${site_url}${data?.data?.dataSources?.__master?.product?._url}`} />
            )}
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: constructProductSchema(data?.data?.dataSources?.__master?.product) }}
            />
          </>
        )}
        {query?.path?.includes('/category/') && data?.data?.dataSources?.__master && (
          <script
            type="application/ld+json"
            id="category"
            dangerouslySetInnerHTML={{ __html: constructCollectionSchema(data?.data?.dataSources?.__master) }}
          ></script>
        )}
        {query?.path == '/' && (
          <>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: constructstructuredDataSchema() }} />
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: constructWebsiteDataSchema() }} />
          </>
        )}
      </Head>

      <FrontasticRenderer data={data} tastics={tastics} wrapperClassName={styles.gridWrapper} />
    </>
  );
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({ params, locale, query, req, res }) => {
  LocaleStorage.locale = locale;

  const frontastic = createClient();
  const data = await frontastic.getRouteData(params, locale, query, req, res);

  if (data) {
    if (data instanceof ResponseError && data.getStatus() == 404) {
      return {
        notFound: true,
      };
    } else if (typeof data === 'object' && 'target' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      };
    }
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error('Error retrieving data: ', data);
    return {
      notFound: true,
    };
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    };
  }

  if ((data as any)!.message === 'Could not resolve page from path') {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      data: data || null,
      locale: locale,
      ...(await serverSideTranslations(locale, [
        'common',
        'cart',
        'product',
        'checkout',
        'account',
        'error',
        'success',
        'wishlist',
        'newsletter',
      ])),
    },
  };
};
